import styled from 'styled-components';
import { device } from '../../../lib/media-query';
import { DropdownInput } from '../../forms/inputs';
import { Button } from '../../shared';
import { SellingPoint } from '../../shared/selling-point/selling-point.component';

export const ContainerGrid = styled.div`
    @media ${device.tablet} {
        display: grid;
        grid-template-areas:
            'sellingPoint prices'
            'sellingPoint benefits'
            'leasingPeriod leasingPeriod'
            'priceSection priceSection'
            'disclaimer disclaimer'
            'leasingActions leasingActions';
        gap: 1em;
        font-size: 14px;
        padding-top: 1em;
    }
`;

export const StyledSellingPointList = styled.div`
    grid-area: sellingPoint;
    margin: 16px 20px 0;
    font-size: 12px;

    margin-bottom: 10px;

    @media ${device.tablet} {
        margin: 16px 0 0 20px;
        margin-bottom: revert;
    }
`;

export const StyledSellingPoint = styled(SellingPoint)`
    @media ${device.tablet} {
        & + & {
            margin: 10px 0 0;
        }
    }
`;

export const OwnershipInfoCardPrices = styled.div`
    grid-area: prices;
    margin: 1.3em 1em 0;
    margin-left: 24px;

    @media ${device.tablet} {
        text-align: right;
        margin-left: revert;
    }
`;

export const StyledPriceWrapper = styled.div`
    font: 700 1.8em/1.2 'Corporate E', Serif;
`;

export const StyledDownPayment = styled.div`
    color: ${(props) => props.theme.palette.text.disabled};
    font: 400 0.86em/1.25 ${({ theme }) => theme.fontFamily.secondary}, Sans-serif;
`;

export const StyledVatMessage = styled.div`
    color: ${(props) => props.theme.palette.text.disabled};
    font: 400 0.86em/1.25 ${({ theme }) => theme.fontFamily.secondary}, Sans-serif;
`;

export const OwnershipInfoCardBenefits = styled(Button)`
    align-self: flex-start;
    font: 500 13px/23px ${({ theme }) => theme.fontFamily.secondary}, Sans-serif;
    grid-area: benefits;
    justify-self: flex-start;
    letter-spacing: 0;
    margin: 0 1em 1em;

    margin-left: 24px;

    @media ${device.tablet} {
        font-size: 1em;
        justify-self: flex-end;
        line-height: 1.71;
        margin-left: revert;
    }
`;

export const StyledLeasingDropdown = styled(DropdownInput)`
    @media ${device.tablet} {
        span {
            font-size: 12px;
        }
    }
`;

export const StyledLeasingActions = styled.div`
    grid-area: leasingActions;
    border-top: 1px solid ${(props) => props.theme.palette.grey.medium};
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 5px 0 0;
    padding: 20px;

    @media ${device.laptopS} {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-between;
    }
`;

export const StyledCtaWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
    width: 100%;
    @media ${device.laptopS} {
        gap: 20px;
        flex-direction: row;
    }
`;

export const StyledContactButton = styled(Button).attrs({
    unsetMinWidth: true,
    variant: 'link',
})`
    flex: 0 0 auto;
`;
export const StyledFooterContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media ${device.mobileL} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const StyledBookTrialButton = styled(Button).attrs({
    unsetMinWidth: true,
    variant: 'light',
})`
    flex: 0 0 auto;
    padding-left: 5px;
    padding-right: 5px;

    @media ${device.laptopS} {
        flex: 1 1 0;
    }
`;

export const StyledCheckoutButton = styled(Button).attrs({
    unsetMinWidth: true,
    variant: 'primary',
})`
    flex: 0 0 auto;
    padding-left: 5px;
    padding-right: 5px;

    @media ${device.laptopS} {
        flex: 1 1 0;
    }
`;

export const StyledDisclaimer = styled.p`
    color: #807f80;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: normal;
    padding: 0 20px;
    grid-area: disclaimer;

    margin-bottom: 20px;
    @media ${device.laptopS} {
        margin-bottom: revert;
    }
`;

export const StyledPositionedDisclaimer = styled.div`
    grid-area: disclaimer;
`;

export const StyledPositionedPriceSection = styled.div`
    grid-area: priceSection;
`;

export const StyledCardFootNote = styled.p`
    color: #aeaeaf;
    font-size: 12px;
`;

export const StyledCardFootNoteContactPhoneNumberText = styled.p`
    font-size: 12px;
`;

export const StyledCardFootNoteContactPhoneNumberLink = styled.a`
    font-size: 12px;
`;

export const StyledHireDropdownWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;

    grid-area: leasingPeriod;
    margin: 10px 20px 20px;
    margin-top: 0;
    margin-bottom: 20px;

    @media ${device.tablet} {
        margin-top: revert;
        margin-bottom: 10px;
        margin: 0 20px 0;
        grid-template-columns: 35% 65%;
    }
`;
